@import "~slick-carousel/slick/slick-theme";
@import "~slick-carousel/slick/slick";

.landing_logo_desktop {
  width: 85%;
  margin-top: 50px;
  position: absolute;
  z-index: 10;
  max-width: 85%;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  pointer-events: none;
  user-select: none;
}

.spinner_landing {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.landing_carousel {
  width: 85%;
  margin: 0 7.5% 0;
  font-size: 16px;
  font-weight: 300;
  display: none;

  &__item {
    &__content {
      position: relative;
      display: flex;
      flex-direction: row;

      &__mobile_reverse p {
        display: none;
      }

      &__text {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;

        &__h1 .landing_h1 {
          font-size: 30px;
          font-weight: 500;
          line-height: 38px;
          text-align: left;
        }

        &__right {
          text-align: right;
          align-items: flex-end;
          position: relative;
          top: 60px;
        }
      }
    }
  }
}

.landing {
  &__mobile {
    &__image_1, &__image_2, &__image_3 {
      display: none !important;
    }
  }
}

.landing_logo_mobile {
  display: none !important;
}

.slick-dots li button {
  position: relative;
  top: 30px;
  width: 10px;
  height: 10px;
  flex-grow: 0;
  margin: 0 12px;
  border-radius: 100%;
  background-color: #33205b;
}

.slick-dots li.slick-active button {
  background-color: #dfd2f9;
}

@media (max-width: 1500px) {
  .landing_logo_desktop {
    width: auto;
    max-height: 35vh;
  }
}

@media (max-width: 768px) {
  .landing_logo_desktop {
    display: none !important;
  }

  .landing_carousel {
    width: 80%;
    margin: 0 10%;

    &__image_box {
      width: 100% !important;
      max-width: 100% !important;
    }

    &__item {
      display: flex;

      .landing_logo_mobile {
        display: block !important;
        width: 1500px;
        height: 411.7px;
        position: absolute;
        top: 30px;
        left: 25px;
        z-index: 20;
        align-items: flex-start;
      }

      &__content {
        flex-direction: column;
        align-items: normal;

        &__mobile_reverse {
          p {

            display: block;
            width: 68vw;
            text-align: center;
            position: relative;
            top: 39px;
          }

          &__second, &__third {
            position: relative;
            top: 19px !important;
          }
        }

        &__text {
          text-align: center !important;
          color: #ffffff;

          &__h1 {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;

            .landing_h1 {
              text-align: center;
              width: 75%;
              position: absolute;
              bottom: 45px;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          button {
            display: none;
          }

          &__right {
            align-items: normal;
            position: static;

            p {
              width: 68vw;
              text-align: center;
              position: relative;
              top: 39px;
            }
          }

          &__left {
            display: none;
          }
        }
      }
    }
  }

  .landing {
    &__image_1, &__image_2, &__image_3 {
      display: none !important;
    }

    &__mobile {
      display: block !important;

      &__image_1, &__image_2, &__image_3 {
        width: 61vw;
        margin: 0 auto;
      }

      &__image_2, &__image_3 {
        position: relative;
        bottom: 20px;
      }
    }
  }
  .slick-dots li button {
    top: unset;
    bottom: 120px;
  }
}

@media (max-width: 650px) {
  .landing_carousel__item__content__text__h1 .landing_h1 {
    bottom: 25px;
  }
}

@media (max-width: 600px) {
  .landing_logo_mobile {
    width: 1350px !important;
    height: 370.6px !important;
    top: 50px !important;
    left: 25px !important;
  }
  .slick-dots li button {
    bottom: 150px;
  }
  .landing_carousel__item__content__text__h1 .landing_h1 {
    font-size: 20px;
    line-height: 19px;
    bottom: 45px;
  }
}

@media (max-width: 550px) {
  .slick-dots li button {
    bottom: 160px;
  }
  .landing_carousel__item__content__text__h1 .landing_h1 {
    bottom: 30px;
  }
}

@media (max-width: 450px) {
  .landing_logo_mobile {
    width: 980px !important;
    height: 269px !important;
  }

  .spinner_landing {
    width: 100px;
  }

  .slick-dots li button {
    bottom: 215px;
  }
  .landing_carousel__item__content__text__h1 .landing_h1 {
    bottom: 5px;
  }
}

@media (max-width: 380px) {
  .slick-dots li button {
    bottom: 238px;
  }
}

@media (max-width: 325px) {
  .landing_logo_mobile {
    width: 740px !important;
    height: 203px !important;
    top: 30px !important;
    left: 15px !important;
  }

  .slick-dots li button {
    bottom: 285px;
  }
}

@media (min-width: 1210px) {
  .landing {
    &__image_1 {
      height: 67vh;
    }

    &__image_2 {
      height: 50vh;
    }

    &__image_3 {
      height: 63vh;
    }
  }
}

@media(max-width: 1390px) and (max-height: 700px) and (min-width: 1200px) and (min-height: 590px) {
  .landing_logo_desktop {
    margin-top: 20px !important;
  }
  .landing_carousel__item__content__text__right {
    top: 75px !important;
  }
}
